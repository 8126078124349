import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, Router, withComponentInputBinding, withHashLocation } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { routes } from './app.routes';
import { TranslateLoaderFactory } from './utils/injection/translate-loader-factory';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { PropertiesService } from './service/properties/properties.service';
import { propertiesProviderFactory } from './service/properties/properties-provider-factory';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withHashLocation()
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: TranslateLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: propertiesProviderFactory,
      deps: [
        PropertiesService
      ],
      multi: true
    },
    provideNativeDateAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
    /*,
    {
      provide: AuthService,
      useFactory: authenticationProviderFactory,
      deps: [
        PersistentStateService,
        PropertiesService
      ]
    }*/
  ]
};
