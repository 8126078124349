<form #subscriptionIdForm="ngForm">

    <mat-form-field>
        <mat-label>{{'subscription_id_request.label' | translate}}</mat-label>

        <input name="payment-id" matInput [(ngModel)]="subscriptionId" #payment_id="ngModel" required>

        @if (subscriptionId().length > 0) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="subscriptionId.set('')">
            <mat-icon>close</mat-icon>
        </button>
        }

        @if((payment_id.dirty || payment_id.touched) &&
        (payment_id.invalid && payment_id.errors?.['required'])
        ) {
        <mat-error>{{'subscription_id_request.required' | translate}}</mat-error>
        }

    </mat-form-field>

    <button mat-raised-button class="find-button" [disabled]="!subscriptionIdForm.form.valid" (click)="onSubmit()">
        <mat-icon>search</mat-icon>
        {{'subscription_id_request.find' | translate}}
    </button>
</form>