import { Component, inject } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Link } from '../../model/link';
import { AdminNavigationService } from '../../admin-navigation/admin-navigation.service';

@Component({
  selector: 'read-subscription',
  standalone: true,
  imports: [
    MatTabsModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './read-subscription.component.html',
  styleUrl: './read-subscription.component.css'
})
export class ReadSubscriptionComponent {
  private adminService = inject(AdminNavigationService);
  route: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);

  links: Link[] = [
    { id: 0, text: 'read-subscription.by_user_id', path: 'user-id' },
    { id: 1, text: 'read-subscription.by_email', path: 'email' },
    { id: 2, text: 'read-subscription.by_payment_id', path: 'payment-id' }
  ];
  activeLink: Link = this.links[0];

  ngOnInit(): void {
    this.adminService.titleId.set('read-subscription.title');
    //this.router.navigate([this.activeLink.path], { relativeTo: this.route });
  }

}
