import { Component, inject, OnInit, signal } from '@angular/core';
import { ReqRespTab, ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { ErrorResponseComponent } from '../../common/error-response/error-response.component';
import { SubscriptionOutputFormComponent } from '../../common/subscription-output-form/subscription-output-form.component';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { PaymentIdRequestComponent } from '../../common/request/payment-id/payment-id-request.component';
import { AdminNavigationService } from '../../../admin-navigation/admin-navigation.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { RouterUrl } from '../../../model/enum/router-url.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'read-subscription-by-payment-id',
  standalone: true,
  imports: [
    ErrorResponseComponent,
    MatIconModule,
    MatButtonModule,
    PaymentIdRequestComponent,
    ReqRespTabGroupComponent,
    SubscriptionOutputFormComponent,
    TranslateModule
  ],
  templateUrl: './read-subscription-by-payment-id.component.html',
  styleUrl: './read-subscription-by-payment-id.component.css'
})
export class ReadSubscriptionByPaymentIdComponent implements OnInit{

  private adminService = inject(AdminNavigationService);
  private router: Router = inject(Router);
  private sapi: SubscriptionApiService = inject(SubscriptionApiService);

  activeTabIndex = signal<ReqRespTab>(ReqRespTab.REQUEST);
  errorResponse = signal<HttpErrorResponse | null>(null);
  errorVisible = signal<boolean>(false);
  responseDisabled = signal<boolean>(false);
  responseVisible = signal<boolean>(false);
  subscription = signal<SapiSubscription | null>(null);
  ngOnInit(): void {

    this.adminService.titleId.set('read-subscription-by-payment-id.title');
  }

  onDelete(): void {

    this.router.navigate(
      [
        RouterUrl.DELETE_BY_ID,
        {
          subscriptionId: this.subscription()?.id
        }
      ]
    );
  }


  onSubmit(paymentId: string): void {

    this.sapi.readByPaymentId(
      paymentId
    ).subscribe(
      {
        next: subscription => {
          this.activeTabIndex.set(ReqRespTab.RESPONSE);
          this.errorVisible.set(false);
          this.responseDisabled.set(false);
          this.responseVisible.set(true);
          this.subscription.set(new SapiSubscription(subscription));
          console.log(subscription);
        },
        error: error => {
          this.activeTabIndex.set(ReqRespTab.ERROR);
          this.errorVisible.set(true);
          this.responseVisible.set(false);
          this.errorResponse.set(error);
          console.log(error);
        }
      }
    );

  }

  onUpdate(): void {

    this.router.navigate(
      [
        RouterUrl.UPDATE_BY_ID,
        {
          subscriptionId: this.subscription()?.id
        }
      ]
    );
  }

}
