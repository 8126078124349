export enum RouterUrl {
    CREATE = 'create',
    CREATE_SINGLE = 'create/single',
    CREATE_MULTIPLE = 'create/multiple',
    READ = 'read',
    READ_BY_EMAIL = 'read/email',
    READ_BY_PAYMENT_ID = 'read/payment-id',
    READ_BY_USER_ID = 'read/user-id',
    UPDATE = 'update',
    UPDATE_BY_EMAIL = 'update/email',
    UPDATE_BY_PAYMENT_ID = 'update/payment-id',
    UPDATE_BY_ID = 'update/subscription-id',
    UPDATE_BY_USER_ID = 'update/user-id',
    DELETE = 'delete',
    DELETE_BY_ID = 'delete/subscription-id',
}