import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { Link } from '../../model/link';
import { AdminNavigationService } from '../../admin-navigation/admin-navigation.service';

@Component({
  selector: 'create-subscription',
  standalone: true,
  imports: [
    MatTabsModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './create-subscription.component.html',
  styleUrl: './create-subscription.component.css'
})
export class CreateSubscriptionComponent implements OnInit {

  private adminService = inject(AdminNavigationService);

  route: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);

  links: Link[] = [
    { id: 0, text: 'create-subscription.single', path: 'single' },
    { id: 1, text: 'create-subscription.multiple', path: 'multiple' }
  ];
  activeLink: Link = this.links[0];

  ngOnInit(): void {

    this.adminService.titleId.set('create-subscription.title');

    //this.router.navigate([this.activeLink.path], { relativeTo: this.route });
  }

}
