import { Component, inject, input, OnInit, signal } from '@angular/core';
import { ErrorResponseComponent } from '../../common/error-response/error-response.component';
import { SubscriptionIdRequestComponent } from '../../common/request/subscription-id/subscription-id-request.component';
import { ReqRespTab, ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { SubscriptionInputFormComponent } from '../../common/subscription-input-form/subscription-input-form.component';
import { SubscriptionOutputFormComponent } from '../../common/subscription-output-form/subscription-output-form.component';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { ConfirmDialogService } from '../../common/confirm-dialog/confirm-dialog.service';
import { DeleteSubscriptionConfirmComponent } from '../delete-subscription-confirm/delete-subscription-confirm.component';
import { AdminNavigationService } from '../../../admin-navigation/admin-navigation.service';

@Component({
  selector: 'delete-subscription-by-subscription-id',
  standalone: true,
  imports: [
    DeleteSubscriptionConfirmComponent,
    ErrorResponseComponent,
    SubscriptionIdRequestComponent,
    ReqRespTabGroupComponent,
    SubscriptionInputFormComponent,
    SubscriptionOutputFormComponent
  ],
  templateUrl: './delete-subscription-by-subscription-id.component.html',
  styleUrl: './delete-subscription-by-subscription-id.component.css'
})
export class DeleteSubscriptionBySubscriptionIdComponent implements OnInit {

  private adminService = inject(AdminNavigationService);

  subscriptionId = input('');

  private confirmDialog = inject(ConfirmDialogService);
  //private route = inject(ActivatedRoute);
  private sapi = inject(SubscriptionApiService);

  activeTabIndex = signal<ReqRespTab>(ReqRespTab.REQUEST);
  errorResponse = signal<HttpErrorResponse | null>(null);
  errorVisible = signal<boolean>(false);
  request2Disabled = signal<boolean>(false);
  request2Visible = signal<boolean>(false);
  responseDisabled = signal<boolean>(false);
  responseVisible = signal<boolean>(false);
  newSubscription = signal<SapiSubscription | null>(null);
  subscription = signal<SapiSubscription | null>(null);

  ngOnInit() {

    this.adminService.titleId.set('delete-subscription-by-subscription-id.title');

    if (this.subscriptionId() !== undefined) {
      this.onSubmitSubscriptionId(this.subscriptionId());
    }
  }


  onSubmitSubscriptionId(subscriptionId: string): void {
    this.sapi.read(
      subscriptionId
    ).subscribe(
      {
        next: subscription => {
          this.activeTabIndex.set(ReqRespTab.REQUEST2);
          this.errorVisible.set(false);
          this.request2Disabled.set(false);
          this.request2Visible.set(true);
          this.responseVisible.set(false);
          this.subscription.set(new SapiSubscription(subscription));
        },
        error: error => {
          this.activeTabIndex.set(ReqRespTab.ERROR);
          this.errorVisible.set(true);
          this.responseVisible.set(false);
          this.errorResponse.set(error);
        }
      }
    );
  }

  onDelete(subscriptionId: string | null): void {

    if (subscriptionId === null) {
      return;
    }

    this.confirmDialog.show(
      'delete_subscription.delete',
      'delete_subscription.confirm'
    ).subscribe(

      confirmed => {

        if (!confirmed) {
          return;
        }

        this.sapi.delete(subscriptionId).subscribe(
          {
            next: subscription => {

              // Show response
              this.newSubscription.set(new SapiSubscription(subscription));
              this.responseVisible.set(true);
              this.activeTabIndex.set(ReqRespTab.RESPONSE);

              // Hide error
              this.errorVisible.set(false);

              // Hide deletion request
              this.request2Visible.set(false);

              console.log(subscription);
            },
            error: error => {
              // Show error
              this.errorResponse.set(error);
              this.errorVisible.set(true);
              this.activeTabIndex.set(ReqRespTab.ERROR);

              // Hide response
              this.responseVisible.set(false);

              // Hide deletion request
              this.request2Visible.set(false);

              console.log(error);
            }
          }

        )
      }

    )


  }


}
