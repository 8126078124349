import { Component, inject, signal } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterModule } from '@angular/router';
import { AdminNavigationService } from './admin-navigation.service';
import { RouterUrl } from '../model/enum/router-url.enum';

@Component({
  selector: 'admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrl: './admin-navigation.component.css',
  standalone: true,
  imports: [
    AsyncPipe,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    RouterModule,
    TranslateModule
  ]
})
export class AdminNavigationComponent {
  private breakpointObserver = inject(BreakpointObserver);

  routerLinkCreate = signal(RouterUrl.CREATE_SINGLE);
  routerLinkRead = signal(RouterUrl.READ_BY_USER_ID);
  routerLinkUpdate = signal(RouterUrl.UPDATE_BY_USER_ID);
  routerLinkDelete = signal(RouterUrl.DELETE_BY_ID);

  private adminService = inject(AdminNavigationService);

  titleId = this.adminService.titleId;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
}
