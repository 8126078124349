//import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, retry, take } from 'rxjs/operators';
import { SubscriptionApiUrlService } from './subscription-api-url.service';
import { PropertiesService } from '../properties/properties.service';
import { SapiHttpHeader } from '../../model/subscription-api/enum/sapi-http-header.enum';
import { ISapiSubscriptionCreate } from '../../model/subscription-api/sapi-subscription-create';
import { ISapiSubscription } from '../../model/subscription-api/sapi-subscription';
import { ISapiSubscriptionUpdate } from '../../model/subscription-api/sapi-subscription-update';
import { ISapiProduct } from '../../model/subscription-api/sapi-product';
import { inject, Injectable } from '@angular/core';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { SessionStorageFeature } from '../session-storage/session-storage-key';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionApiService {

    private readonly http: HttpClient = inject(HttpClient);
    private readonly properties: PropertiesService = inject(PropertiesService);
    private readonly sessionStorage: SessionStorageService = inject(SessionStorageService);
    private readonly urls: SubscriptionApiUrlService = inject(SubscriptionApiUrlService);

    private readonly httpDeleteHeaders: HttpHeaders;
    private readonly httpGetHeaders: HttpHeaders;
    private readonly httpPostHeaders: HttpHeaders;
    private readonly httpRequestRetryCount: number;

    constructor() {
        this.httpRequestRetryCount = this.properties.httpRequestRetryCount

        this.httpDeleteHeaders = new HttpHeaders(
            {
                [SapiHttpHeader.ACCEPT]: 'application/json',
                [SapiHttpHeader.API_KEY]: this.properties.subscriptionApi.apiKey
            }
        );

        this.httpGetHeaders = new HttpHeaders(
            {
                [SapiHttpHeader.ACCEPT]: 'application/json',
                [SapiHttpHeader.API_KEY]: this.properties.subscriptionApi.apiKey
            }
        );
        this.httpPostHeaders = new HttpHeaders(
            {
                [SapiHttpHeader.ACCEPT]: 'application/json',
                [SapiHttpHeader.API_KEY]: this.properties.subscriptionApi.apiKey,
                [SapiHttpHeader.CONTENT_TYPE]: 'application/json'
            }
        );
    }

    public create(
        productId: string,
        subscriptionCreate: ISapiSubscriptionCreate
    ): Observable<ISapiSubscription> {

        const url: string =
            this.urls.getCreateUrl(productId);

        return this.http.post<ISapiSubscription>(
            url,
            subscriptionCreate,
            {
                headers: this.httpPostHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleCreateError)
        );
    }



    public read(
        subscriptionId: string
    ): Observable<ISapiSubscription> {

        const url: string =
            this.urls.getReadUrl(subscriptionId);

        return this.http.get<ISapiSubscription>(
            url,
            {
                headers: this.httpGetHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleReadError)
        )
    }


    public readByUserId(userId: string): Observable<ISapiSubscription> {

        const url: string =
            this.urls.getReadByUserIdUrl(userId);

        return this.http.get<ISapiSubscription>(
            url,
            {
                headers: this.httpGetHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleReadError)
        )
    }

    public readByEmail(
        productId: string,
        email: string
    ): Observable<ISapiSubscription[]> {

        const url: string =
            this.urls.getReadByEmailUrl(productId, email);

        return this.http.get<ISapiSubscription[]>(
            url,
            {
                headers: this.httpGetHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleReadError)
        )
    }


    public readByPaymentId(
        paymentId: string
    ): Observable<ISapiSubscription> {

        const url: string =
            this.urls.getReadByPaymentIdUrl(paymentId);

        return this.http.get<ISapiSubscription>(
            url,
            {
                headers: this.httpGetHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleReadError)
        )
    }


    public readProducts(): Observable<ISapiProduct[]> {

        if (this.sessionStorage.has(SessionStorageFeature.PRODUCT_LIST)) {

            return of<ISapiProduct[]>(
                this.sessionStorage.get(
                    SessionStorageFeature.PRODUCT_LIST) as ISapiProduct[]
            );
        } else {
            const url: string =
                this.urls.getReadProductsUrl();

            const productList$: Observable<ISapiProduct[]> = this.http.get<ISapiProduct[]>(
                url,
                {
                    headers: this.httpGetHeaders
                }
            ).pipe(
                retry(this.httpRequestRetryCount),
                catchError(this.handleReadError)
            );
            productList$.subscribe(
                productList => {
                    this.sessionStorage.set(
                        SessionStorageFeature.PRODUCT_LIST,
                        productList
                    );
                }
            );

            return productList$;
        }

    }

    public update(
        productId: string,
        subscriptionId: string,
        subscriptionUpdate: ISapiSubscriptionUpdate
    ): Observable<ISapiSubscription> {

        const subject$$: Subject<ISapiSubscription> =
            new Subject();

        const url: string =
            this.urls.getUpdateUrl(productId, subscriptionId);

        this.http.put<ISapiSubscription>(
            url,
            subscriptionUpdate,
            {
                headers: this.httpPostHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleUpdateError)
        ).subscribe(
            subscription =>
                subject$$.next(
                    subscription
                    //new SapiSubscription(subscription)
                ),
            error => subject$$.error(error)
        );

        return subject$$
            .asObservable()
            .pipe(take(1));
    }

    public delete(subscriptionId: string): Observable<ISapiSubscription> {


        const url: string =
            this.urls.getDeleteUrl(subscriptionId);

        return this.http.delete<ISapiSubscription>(
            url,
            {
                headers: this.httpDeleteHeaders
            }
        ).pipe(
            retry(this.httpRequestRetryCount),
            catchError(this.handleUpdateError)
        );
    }


    private handleCreateError(error: HttpErrorResponse) {

        let errorId: string = 'login_page.connection_failed';

        if (error.status === 0) {

            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);

        } else {

            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);

            // Status === 400 - Bad developer
            // Status === 401 - Bad password
            // Status === 404 - Bad user ID
            // Status === 500 - Problems on the server
            errorId = 'subscription_api.create_error_' + error.status;
        }

        return throwError(error);
    }
    private handleReadError(error: HttpErrorResponse) {

        let errorId: string = 'login_page.connection_failed';

        if (error.status === 0) {

            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);

        } else {

            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);

            // Status === 400 - Bad developer
            // Status === 401 - Bad password
            // Status === 404 - Bad user ID
            // Status === 500 - Problems on the server
            errorId = 'subscription_api.read_error_' + error.status;
        }

        return throwError(error);
    }

    private handleUpdateError(error: HttpErrorResponse) {

        let errorId: string = 'login_page.connection_failed';

        if (error.status === 0) {

            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);

        } else {

            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);

            // Status === 400 - Bad developer
            // Status === 401 - Bad password
            // Status === 404 - Bad user ID
            // Status === 500 - Problems on the server
            errorId = 'subscription_api.update_error_' + error.status;
        }

        return throwError(errorId);
    }

    private handleDeleteError(error: HttpErrorResponse) {

        let errorId: string = 'login_page.connection_failed';

        if (error.status === 0) {

            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);

        } else {

            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);

            // Status === 400 - Bad developer
            // Status === 401 - Bad password
            // Status === 404 - Bad user ID
            // Status === 500 - Problems on the server
            errorId = 'subscription_api.delete_error_' + error.status;
        }

        return throwError(errorId);
    }

}

