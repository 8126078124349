import { Component, inject } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Link } from '../../model/link';
import { AdminNavigationService } from '../../admin-navigation/admin-navigation.service';

@Component({
  selector: 'delete-subscription',
  standalone: true,
  imports: [
    MatTabsModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './delete-subscription.component.html',
  styleUrl: './delete-subscription.component.css'
})
export class DeleteSubscriptionComponent {
  
  private adminService = inject(AdminNavigationService);
  route: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);

  links: Link[] = [
    { id: 0, text: 'delete-subscription.by_subscription_id', path: 'subscription-id' }
  ];
  activeLink: Link = this.links[0];

  ngOnInit(): void {
    this.adminService.titleId.set('delete-subscription.title');
    this.router.navigate([this.activeLink.path], { relativeTo: this.route });
  }
}
