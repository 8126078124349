<form #findByEmailForm="ngForm">

    <product-select (productSelected)="onProductSelected($event)"></product-select>

    <email-input [(email)]="email" [(isValid)]="emailValid"></email-input>

    <button mat-raised-button class="find-button" [disabled]="!findByEmailForm.form.valid || !emailValid()"
        (click)="onSubmit()">
        <mat-icon>search</mat-icon>
        {{'button.find' | translate}}
    </button>

</form>