import { Component, output, signal } from '@angular/core';
import { ProductSelectComponent } from '../../product-select/product-select.component';
import { ISapiProduct } from '../../../../model/subscription-api/sapi-product';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'user-id-request',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ProductSelectComponent,
    TranslateModule
  ],
  templateUrl: './user-id-request.component.html',
  styleUrl: './user-id-request.component.css'
})
export class UserIdRequestComponent {

  submitRequest = output<string>();

  hidePassword = signal<boolean>(true);
  productId = signal<string>('');
  userId = signal<string>('');


  onProductSelected(product: ISapiProduct | undefined) {
    if (product) {
      this.productId.set(product.id);
    }
  }

  onSubmit(): void {
    this.submitRequest.emit(this.userId().trim());
  }
}
