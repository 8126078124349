<req-resp-tab-group
    [(activeTabIndex)]="activeTabIndex"
    [errorVisible]="errorVisible()"
    [request2Disabled]="request2Disabled()"
    [request2Visible]="request2Visible()"
    [responseDisabled]="responseDisabled()" 
    [responseVisible]="responseVisible()" 
>
    <subscription-id-request class="req-tab" (submitRequest)="onSubmitSubscriptionId($event)">
    </subscription-id-request>

    <delete-subscription-confirm class="req-2-tab" [subscription]="subscription()" (delete)="onDelete($event)">
    </delete-subscription-confirm>

    <subscription-output-form class="resp-tab" [subscription]="newSubscription()">
    </subscription-output-form>
    
    <error-response class="error-tab" [error]="errorResponse()">
    </error-response>
</req-resp-tab-group>