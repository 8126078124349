<req-resp-tab-group
    [(activeTabIndex)]="activeTabIndex"
    [errorVisible]="errorVisible()"
    [request2Disabled]="true"
    [request2Visible]="false"
    [responseDisabled]="responseDisabled()" 
    [responseVisible]="responseVisible()" 
>
    <create-subscription-single-request class="req-tab" (submitRequest)="onSubmit($event)">
    </create-subscription-single-request>

    <div class="resp-tab">
        <subscription-output-form [subscription]="subscription()">
        </subscription-output-form>
        <button mat-raised-button class="update-button" (click)="onUpdate()">
            <mat-icon>delete</mat-icon>
            {{'button.update' | translate}}
        </button>
        <button mat-raised-button color="warn" class="delete-button" (click)="onDelete()">
            <mat-icon>delete</mat-icon>
            {{'button.delete' | translate}}
        </button>
    </div>
    
    <error-response class="error-tab" [error]="errorResponse()">
    </error-response>
</req-resp-tab-group>