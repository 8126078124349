import { Component, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ProductSelectComponent } from '../../product-select/product-select.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'subscription-id-request',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ProductSelectComponent,
    TranslateModule
  ],
  templateUrl: './subscription-id-request.component.html',
  styleUrl: './subscription-id-request.component.css'
})
export class SubscriptionIdRequestComponent {

  submitRequest = output<string>();

  subscriptionId = signal<string>('');

  onSubmit(): void {
    this.submitRequest.emit(this.subscriptionId().trim());
  }


}
