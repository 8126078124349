import { Component, inject, OnInit, signal } from '@angular/core';
import { ReqRespTab, ReqRespTabGroupComponent } from '../../common/req-resp-tab-group/req-resp-tab-group.component';
import { EmailRequestComponent } from '../../common/request/email-request/email-request.component';
import { IEmailRequest } from '../../../model/request/i-email.request';
import { SubscriptionApiService } from '../../../service/subscription-api/subscription-api.service';
import { ErrorResponseComponent } from '../../common/error-response/error-response.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SapiSubscription } from '../../../model/subscription-api/sapi-subscription';
import { SubscriptionListComponent } from '../../common/subscription-list-output-form/subscription-list/subscription-list.component';
import { AdminNavigationService } from '../../../admin-navigation/admin-navigation.service';

@Component({
  selector: 'read-subscription-by-email',
  standalone: true,
  imports: [
    ErrorResponseComponent,
    EmailRequestComponent,
    ReqRespTabGroupComponent,
    SubscriptionListComponent
  ],
  templateUrl: './read-subscription-by-email.component.html',
  styleUrl: './read-subscription-by-email.component.css'
})
export class ReadSubscriptionByEmailComponent implements OnInit {

  private adminService = inject(AdminNavigationService);
  sapi: SubscriptionApiService = inject(SubscriptionApiService);

  activeTabIndex = signal<ReqRespTab>(ReqRespTab.REQUEST);
  errorResponse = signal<HttpErrorResponse | null>(null);
  errorVisible = signal<boolean>(false);
  responseDisabled = signal<boolean>(false);
  responseVisible = signal<boolean>(false);
  subscriptions = signal<SapiSubscription[]>([]);

  ngOnInit(): void {

    this.adminService.titleId.set('read-subscription-by-email.title');
  }

  onSubmit(params: IEmailRequest): void {
    this.sapi.readByEmail(
      params.productId,
      params.email
    ).subscribe(
      {
        next: subscriptions => {
          this.activeTabIndex.set(ReqRespTab.RESPONSE);
          this.errorVisible.set(false);
          this.responseDisabled.set(false);
          this.responseVisible.set(true);
          this.subscriptions.set(
            subscriptions.map(
              iSubscription =>
                new SapiSubscription(iSubscription)
            )
          );
          console.log(subscriptions);
        },
        error: error => {
          this.activeTabIndex.set(ReqRespTab.ERROR);
          this.errorVisible.set(true);
          this.responseVisible.set(false);
          this.errorResponse.set(error);
          console.log(error);
        }
      }
    );

  }


}
