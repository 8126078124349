<table mat-table [dataSource]="subscriptions()" multiTemplateDataRows class="mat-elevation-z8">
    @for (column of columnsToDisplay(); track column) {
    <ng-container matColumnDef="{{column}}">
        <th mat-header-cell *matHeaderCellDef> {{'subscription_list.column_'+column | translate}} </th>
        <td mat-cell *matCellDef="let element">
            @if (column == 'creationDate') {
            {{element[column].toLocaleDateString('de-CH',{day: '2-digit',month: '2-digit',year: 'numeric'})}}
            } @else {
            @if (column == 'lastAccessTime') {
            {{element[column].toLocaleString('de-CH',{day: '2-digit',month: '2-digit',year: 'numeric',hour:
            '2-digit',minute: '2-digit'})}}
            } @else {
            {{element[column]}}
            }
            }
        </td>
    </ng-container>
    }

    <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row" (click)="
                    expandedElement.set( 
                        expandedElement() === element ? null : element
                    );
                 $event.stopPropagation()">

                @if (expandedElement() === element) {
                <mat-icon>keyboard_arrow_up</mat-icon>
                } @else {
                <mat-icon>keyboard_arrow_down</mat-icon>
                }
            </button>
        </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">

        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand().length">

            <div class="example-element-detail"
                [@detailExpand]="element == expandedElement() ? 'expanded' : 'collapsed'">

                @if(inputForm()) {

                <subscription-input-form [subscription]="element"
                    (update)="onUpdate(element.productId,element.id, $event)"></subscription-input-form>

                } @else {

                <subscription-output-form [subscription]="element"></subscription-output-form>

                <div class="buttons-container">
                    <button mat-raised-button class="update-button" (click)="onUpdateOutput(element.id)">
                        <mat-icon>delete</mat-icon>
                        {{'button.update' | translate}}
                    </button>

                    <button mat-raised-button color="warn" class="delete-button" (click)="onDelete(element.id)">
                        <mat-icon>delete</mat-icon>
                        {{'button.delete' | translate}}
                    </button>
                </div>
                }
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand()"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand();" class="example-element-row"
        [class.example-expanded-row]="expandedElement() === element"
        (click)="expandedElement.set( expandedElement() === element ? null : element)">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>